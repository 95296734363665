/**
 * Release flags are used to hide features still in active development
 * from practices in production.  A practice's release flags are managed in Mesa.
 * The useReleaseCheck("flag") hook is used to check if a flag is enabled for a
 * given practice.
 */

import { Product } from "@remhealth/apollo";

// WARNING: When removing from this list, move to the deprecatedFlags list
export const releaseFlags = [
  "TextPrediction",
  "WebGT",
  "PatientCards",
  "CTOnePatientSignatures",
  "RedundantMuchImportLibrary",
  "Authorizations",
  "GMDNotesTab",
  "WebScribe",
  "WebScribePause",
  "ProgramLocation",
  "MultiServiceNote",
  "ProgramLocationValidation",
  "DiagnosisLimit",
  "SupervisorSyncUpdate",
  "ImportCNSCalculatedUpdate",
  "MobilePatientSignatures",
  "GroupNotePreferredProgram",
  "RadioButtonDeselect",
  "GroupNoteTypeSummary",
  "SupervisorDashProgramFilter",
  "CNSChoiceQuestionConvert",
  "UserProfileRolePlaceholders",
  "ValidationCopy",
  "SpanMidnightOutcome",
  "PractitionerSignatureSignerType",
  "myEvolvWorkgroupUpdate",
  "GoalCommentCNS",
  "CNSImportDurationCalculation",
  "GroupNoteDisplays",
  "CNSQuestionShowStatusFilter",
  "GroupSummaryUpdate",
  "InsuranceValidation",
  "GroupNoteAssignmentRemember",
  "VirtualScribeConsentTab",
  "NoteTypeHideText",
  "MultiServiceOverride",
] as const;

type VersionReleaseMeta = readonly [product: Product, versionFlag: `${"myAvatar" | "myEvolv" | "gmd"}${string}`, ehrReleaseDescription: string];

// This list is for release flags that have a verison dependency with the customer's EHR version
export const versionedReleaseMeta = [
  ["myAvatar", "myAvatarDocRouting", "Avatar CareFabric 2023 Update 6"],
  ["myAvatar", "myAvatarCaseLoadTags", "TBD - AV-84532"],
  ["myAvatar", "myAvatarEBPNoteSection", "Avatar CareFabric 2023 Update 6"],
  ["myAvatar", "myAvatarAddOn", "Avatar CareFabric 2023 Update 29, 39 & 48"],
  ["myAvatar", "myAvatarValueSetPaging", "TBD - AV-94050"],
  ["myAvatar", "myAvatarPatientSignatures", "Avatar CareFabric 2024 Update 16 - AV-91980"],
  ["myAvatar", "myAvatarPDFGeneration", "Avatar CareFabric 2024 Update 16 - AV-91084"],
  ["myAvatar", "myAvatarNoteTypeFilter", "Avatar CareFabric 2023 Update 29"],
  ["myAvatar", "myAvatarGroupNotePutAppointment", "Avatar CareFabric 2024 Update 31, Update 6, and Appointment Scheduling 2024 Update 4 - AV-94884"],
  ["myAvatar", "myAvatarAuthorizations", "Avatar CareFabric 2024 Update 27"],
  ["myAvatar", "myAvatarSupervisors", "CareFabric 2024 Update 42"],
  ["myAvatar", "myAvatarAssessmentNotes", "Avatar CareFabric 2024 Update 055"],
  ["myAvatar", "myAvatarMissedVisit", "Avatar CareFabric 2024 Update 046"],
  ["myAvatar", "myAvatarGroupOnlyAppointments", "Avatar CareFabric 2024 Update 61 - AV-86999, AV-101510"],
  ["myAvatar", "myAvatarServiceProgramCodeFiltering", "AV-101405"],
  ["myAvatar", "myAvatarListDiagnosisPaging", "CareFabric 2024 Update 84 or 2024.04.00 - AV-103474"],
  ["myAvatar", "myAvatarZeroDuration", "2024.04.00 - AV-102291"],

  ["myEvolv", "myEvolvTxPlanActiveDateFilter", "TBD - ECS-58350"],
  ["myEvolv", "myEvolvDocRouting", "11.0.0050 - Q2 2023"],
  ["myEvolv", "myEvolvNoteAppointment", "11.0.0050.01 - Q2 2023"],
  ["myEvolv", "myEvolvTestAssessments", "11.0.0075 - Q3 2023"],
  ["myEvolv", "myEvolvPatientSignatures", "11.0.0075 - Q3 2023"],
  ["myEvolv", "myEvolvDocumentUpload", "11.0.0075 - Q3 2023"],
  ["myEvolv", "myEvolvGroups", "11.0.0100 - ECS-57665"],
  ["myEvolv", "myEvolvSubForms", "11.0.0125 - ECS-60965"],
  ["myEvolv", "myEvolvGroupCNS", "11.0.0125 - ECS-63008"],
  ["myEvolv", "myEvolvSupervisors", "11.0.0150"],
  ["myEvolv", "myEvolvGroupForms", "TBD - ECS-63008"],
  ["myEvolv", "myEvolvTimezoneFixes", "11.0.0150 - ECS-61678"],
  ["myEvolv", "myEvolvGroupRegistration", "TBD - ECS-63891"],

  ["GEHRIMED", "gmdCensus", "Not doing, since GMD integration approach is changing - GetGroupResource CF Action"],
  ["GEHRIMED", "gmdPagedVisits", "Not doing, since GMD integration approach is changing - ListVisit supports paging and sorting"],
] as const satisfies ReadonlyArray<VersionReleaseMeta>;

export const versionedReleaseFlags = versionedReleaseMeta.map(r => r[1]);

// WARNING: When removing from this list, move to the deprecatedFlags list
export const mobileReleaseFlags = [
  "MobileGT",
  "MobileGTGoalSelectionUpdate",
  "MobileScribeDictated",
  "MobileScribeButtonUpdate",
  "MobilePatientSignatures",
  "MobilePatientAddressUpdate",
] as const;

// Only remove from this list once all users have been upgraded past the desktop and mobile version that supported these flags
export const deprecatedReleaseFlags = [
  "ValidationsLocation",
  "ValidationMissingInformationUpdate",
  "ValidationStartNote",
  "ClinicalRecommendationRedundantMuch",
  "UserPlaceholders",
  "ProblemLabelSubstitution",
  "NoteAudit",
  "NoteOverlapCheck",
  "NoteAuditCNS",
  "MobileSessionEdit",
  "MobileEHRStartNoteUpdate",
  "UnsignedNoteReminder",
  "MonthlyUsageEmail",
  "NoteReportsNewColumns",
  "ReportCSVFilterDateTime",
  "CNSNewYesNo",
  "CNSFilterServiceLocation",
  "CNSAnswerFilter",
  "NoteNarrativeMode",
  "HasAdmissionDiagnosis",
  "myEvolvPatientListWorkgroup",
  "myAvatarMissingInformationAdmissionDx",
  "MyAvatarAdminNoteDelete",
  "MapNoteNarrative",
  "NativeLanguageMode",
  "CTOneAdminNoteDelete",
  "SessionTimeSignUpdate",
  "GTGoalSelectionUpdate",
  "ProgressNotePreview",
  "DiagnosisEHRToggle",
  "NoteTypeProgramLimit",
  "SignNoteUpdate",
  "SecondarySignNoteUpdate",
  "EHRStartNoteUpdate",
  "SessionTimeDurationMinutes",
  "NoteTypeGoalsRequired",
  "NoteTypeGoalsMinimumLevel",
  "NoteTypeGoalsRequireComment",
  "PartialLanguageChecks",
  "DocRoutingSearch",
  "NoteTypePreview",
  "GroupNoteLocationOverride",
  "GroupNoteShowStatusServiceOverride",
  "GroupNoteServiceRestrict",
  "NoteTypeRedesign",
  "MobileProblemTxUpdate",
  "MobileSessionDisplayHide",
  "MobileScribblesTeam",
  "MobileExpansionPreference",
  "MobileOCR",
  "AuditSummaryReportUpdate",
  "SignedNoteExportAdditions",
  "SignedNoteReportSearch",
  "UnsignedNoteExportAdditions",
  "SyncIssuesExportAdditions",
  "NoteTypeCNSDisplayName",
  "NoteTypeCNSRequiredText",
  "EBPElements",
  "ValidationsPreventIgnore",
  "RedundantMuchUpdate",
  "ValidationsSessionIncrements",
  "GroupNoteValidations",
  "SessionDurationZero",
  "NoteHeaderUpdate",
  "CNSDateTimeUpdate",
  "CNSStatusFilter",
  "CNSNoteTypeValidation",
  "CNSLongTextLink",
  "HeaderImport",
  "CNSDecisionOnly",
  "CNSNumericRange",
  "CNSDecisionOnlyMultiChoice",
  "CNSImportDateTimeValidation",
  "DashboardComparison",
  "DocumentationNote",
  "SyncIssueWidget",
  "PatientChartTreatmentPlan",
  "WebOCR",
  "myAvatarFutureDateTimeHold",
  "myEvolvPlaceOfServiceFilter",
  "MyEvolvEnrollmentSidebar",
  "GroupNoteMultipleAssignTherapist",
  "GroupNoteTypeGroupings",
  "GroupSettings",
  "GroupAdminEdit",
  "GroupNoteForceOverride",
  "GroupNoteAgendaCheck",
  "GroupNoteAdminServiceOverride",
  "WebCallMasking",
  "OrganizationPreferenceRedesign",
  "OrgPreferenceHoldNotes",
  "MobileSessionScribbleShare",
] as const;

export type ReleaseFlags = (typeof releaseFlags)[number] | (typeof versionedReleaseFlags)[number];
export type MobileReleaseFlags = (typeof mobileReleaseFlags)[number];
export type DeprecatedReleaseFlags = (typeof deprecatedReleaseFlags)[number];
